<template>
  <b-modal ref="zds-reminder-modal" hide-title hide-footer size="lg">
    <template #modal-header="{ close }">
      <svg-cross-icon @click="close()" />
    </template>
    <p><strong>DÔLEŽITÉ!!!</strong></p>
    <p>Vážená pani učiteľka/vážený pán učiteľ.</p>
    <p>
      Ak ste už so žiakmi ukončili svoj modul v programe Zmudri do škôl, prosím
      zašlite im postojové dotazníky. Následne od nás obdržíte report vďaka
      ktorému zistíte, ako sú na tom Vaši žiaci v porovnaní so zvyškom
      Slovenska. Link na dotazníky nájdete v e-mail komunikácii s
      <a href="mailto:nikoleta.karasova@zmudri.sk" class="link"
        >nikoleta.karasova@zmudri.sk</a
      >
    </p>
    <p>Ďakujeme!</p>
  </b-modal>
</template>

<script>
export default {
  components: {
    "svg-cross-icon": () => import("/assets/icons/cross.svg?inline"),
  },

  methods: {
    showModal(e) {
      this.group = e;
      this.$nextTick(() => {
        this.$refs["zds-reminder-modal"].show();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  cursor: pointer;
}

h4 {
  font-family: Boing !important;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #2b2e4a;
}

::v-deep .modal-header {
  border-bottom: 0 none;
}

::v-deep .modal-content {
  top: 150px;
}

::v-deep .modal-body {
  a.link {
    font-size: inherit !important;
    color: #0165ff !important;
    text-decoration: none !important;

    &:hover {
      text-decoration: underline !important;
    }
  }

  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    align-items: center;

    color: #000000;
  }
  .modal-box {
    align-items: center;
    width: 569px;
    height: 94px;
    left: 617px;
    top: 847px;

    background: #fff5d1;
    border: 1px solid #e6d8a7;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .modal-box-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;

    letter-spacing: -0.05em;

    color: #cab979;
  }
}
input {
  border: none;
  border-bottom: 1px solid #cadeea;
  width: 100%;
  &::placeholder {
    color: rgba(156, 161, 174, 0.4) !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
  }
  &:focus {
    outline: 0;
  }
  &:read-only {
    background-color: transparent;
  }
}

::v-deep .modal-content {
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-image: linear-gradient(90.01deg, #0064ff 60.75%, #fb2564 79.89%) 0 0
    100% 0/0 0 4.53px 0 stretch;
}

::v-deep .modal-footer {
  border-top: 0 none !important;
  align-items: left;
}

.modal-btn {
  width: 221px;
  height: 54px;
  left: 782px;
  top: 767px;
}

.box-content {
  margin: auto;
  padding: 20px;
  justify-content: center;
}

.box-bottom {
  width: 100%;
  background: #fff5d1;
  border: 1px solid #e6d8a7;
  box-sizing: border-box;
  align-items: center;
  border-radius: 4px;
}
</style>
